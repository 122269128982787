<template>
  <div class="artExhibition">
    <!-- id:{{ href }} -->
    <div class="artExhibition_search">
      <van-search
        v-model="value"
        shape="round"
        placeholder="搜作品/找艺术家"
        @search="searchClcik"
      />
      <van-icon
        name="user-circle-o"
        color="#333333"
        size="18"
        @click="handleGotoOne"
      />
    </div>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="item in banners" :key="item.Id">
        <img :src="item.ImgUrl" :alt="item.Name" srcset="" />
      </van-swipe-item>
    </van-swipe>
    <van-row class="tabs">
      <van-col
        span="6"
        v-for="(t, i) in tabs"
        :key="i"
        @click="handleGoto(t, i)"
      >
        <!-- <router-link :to="{ path: t.path }"> -->
        <div v-if="i != 3 && i != 4">
          <img :src="t.img" :alt="t.value" srcset="" />
          <p>{{ t.value }}</p>
        </div>
        <div
          v-if="
            i == 3 &&
            (!$route.query.UserId ||
              artInfo == null ||
              artInfo.Status == -1 ||
              artInfo.Status == 0)
          "
        >
          <img :src="t.img" :alt="t.value" srcset="" />
          <p>{{ t.value }}</p>
        </div>
        <!-- $route.query.UserId != '' &&  -->
        <div
          v-if="
            i == 4 &&
            artInfo != null &&
            $route.query.UserId &&
            artInfo.Status == 1
          "
        >
          <img :src="t.img" :alt="t.value" srcset="" />
          <p>{{ t.value }}</p>
        </div>
        <!-- </router-link> -->
      </van-col>
    </van-row>
    <div class="he_10"></div>
    <div class="title">最新入驻</div>
    <div class="contract">
      <ul>
        <li v-for="(s, i) in signContractList" :key="i">
          <div class="headimg">
            <router-link
              :to="{
                path: '/H5/art/authorDetails',
                query: {
                  id: s.ArtistId,
                  type: 1,
                },
              }"
            >
              <img :src="s.HeadImg" alt="" srcset="" />
              {{ s.ArtistName }}
            </router-link>
          </div>
          <div class="c_img">
            <img
              :src="a.CoverImg"
              v-for="(a, j) in s.ArtWorks.slice(0, 3)"
              :key="j"
              @click="artClick(a.Id)"
            />
          </div>
        </li>
      </ul>
    </div>
    <div class="he_10"></div>
    <div class="title">最新发布</div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getQueryNewestArtExhibition"
      v-if="artList.length > 0"
    >
      <ul class="release">
        <li v-for="(a, i) in artList" :key="i">
          <div class="works_img">
            <img :src="a.ArtImg" @click="artClick(a.ArtId)" alt="" srcset="" />
            <div class="works_introduce">
              <p class="decimalPoint-2">{{ a.ArtTitle }}</p>
              <!-- <p>{{ a.ArtistHeadImg }}</p> -->
            </div>
          </div>
          <div class="headimg">
            <router-link
              :to="{
                path: '/H5/art/authorDetails',
                query: {
                  id: a.ArtistId,
                  type: 1,
                },
              }"
            >
              <img :src="a.ArtistHeadImg" alt="" srcset="" />
              {{ a.ArtistName }}
            </router-link>
          </div>
        </li>
      </ul>
    </van-list>
    <div v-if="artList.length <= 0">
      <no-data></no-data>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {
  queryBanners,
  queryNewestArtist,
  queryNewestArtExhibition,
  querySupplierByStudentId,
} from "@/api/art";
import { queryStudentInfoById } from "@/api/bbs";
import noData from "@/components/noData";
import { Dialog } from "vant";
export default {
  components: { noData },
  computed: {},
  data () {
    return {
      value: "",
      banners: [],
      tabs: [
        {
          img: require("@/assets/images/icon_zzp.png"),
          value: "找作品",
          path: "/H5/art/list",
        },
        {
          img: require("@/assets/images/icon_ysp.png"),
          value: "文创品",
          path: "/H5/art/list",
        },
        {
          img: require("@/assets/images/icon_ysj.png"),
          value: "艺术家",
          path: "/H5/art/artists",
        },
        {
          img: require("@/assets/images/icon_ysjrz.png"),
          value: "艺术家入驻",
          path: "/H5/art/artistRegister",
        },
        {
          img: require("@/assets/images/icon_ysjfb.png"),
          value: "艺术品发布",
          path: "/H5/art/artworkAdd",
        },
      ],
      signContractList: [],
      artList: [],
      loading: false,
      finished: false,
      pageSize: 10,
      pageIndex: 1,
      artInfo: {},
      user: {},
      route: {},
    };
  },
  created () {
    // this.href = location.href + ''
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      // that.$message.error(JSON.stringify(jsonStr));
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.route.UserId = that.user.Id;
        that.getQueryUserInfo();
        that.getQuerySupplierByStudentId();
      } else {
        that.$message.error("登录失败");
      }
    };
    this.init();
    this.getQueryNewestArtExhibition();
  },
  mounted () {
    // this.callApp();
    localStorage.setItem(
      "artUserId",
      this.$route.query.UserId ? this.$route.query.UserId : ""
    );
    this.route = this.$route.query;
    if (this.$route.query.UserId) {
      this.user.Id = this.$route.query.UserId;
      this.getQuerySupplierByStudentId();
    } else {
      localStorage.removeItem("artUserId");
    }
  },
  methods: {
    handleGoto (t, i) {
      let path = t.path;
      if (i == 3 || i == 4) {
        // this.$message.error("功能开发中，敬请期待！");
        // return false

        if (!this.$route.query.UserId) {
          this.callApp();
          return false;
        }
        // Status 0 未通过 1审核通过
        if (i == 4 && this.artInfo.Status != 1) {
          Dialog.alert({
            message: "入驻信息审核中，请耐心等待",
            theme: "round-button",
            confirmButtonColor: "#ee0a24",
          }).then(() => {
            // on close
          });
          // this.$message.error("审核中，请等待！");
          return false;
        }
        if (i == 3) {
          path = path + "?UserID=" + this.$route.query.UserId;
        } else {
          path =
            path +
            "?UserID=" +
            this.$route.query.UserId +
            "&artistId=" +
            this.artInfo.Id;
        }
      }
      this.$router.push(path);
    },
    handleGotoOne () {
      // this.$message.error("功能开发中，敬请期待！");
      // return false
      let path = "/H5/art/artistIntroduction";
      if (!this.$route.query.UserId) {
        this.callApp();
        return false;
      }
      // console.log(this.artInfo, '')
      if (this.artInfo?.Id == undefined) {
        this.$message.error("申请尚未完成");
        return false;
      }
      path = path + "?id=" + this.artInfo.Id;
      this.$router.push(path);
    },
    userIDResultByIos (jsonStr) {
      this.$message.error("IOS回调");
      // if (jsonStr != "") {
      //   this.user.Id =JSON.parse(jsonStr).data;
      //   this.getQueryUserInfo();
      // } else {
      //   this.$message.error("登录失败");
      // }
    },
    callApp () {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(
          JSON.stringify({ action: "callIosGetUserID" })
        );
      }
    },
    // 根据学生ID获取艺术家详情
    async getQuerySupplierByStudentId () {
      let studentId = "?studentId=" + this.user.Id; // this.route.UserId
      const res = await querySupplierByStudentId(studentId);
      this.artInfo = res.data.response;
      localStorage.setItem("artInfo", JSON.stringify(this.artInfo));
    },
    // 用户详情
    async getQueryUserInfo () {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
    artClick (id) {
      // let url = "/shop/goods/detail/" + id
      let url =
        "/H5/art/artistsDetails?id=" +
        id +
        "&artUserId=" +
        localStorage.getItem("artUserId");
      this.$router.push({ path: url });
    },
    searchClcik () {
      this.$router.push({
        name: `H5/art/list`,
        params: { keyWords: this.value },
      });
    },
    async getQueryNewestArtExhibition () {
      let parm = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      const res = await queryNewestArtExhibition(parm);
      this.artList = this.artList.concat(res.response.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.artList.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    async init () {
      let parm = {
        pageIndex: 1,
        pageSize: 10,
        category: 5, //	4：艺术展PC，5：艺术展APP
      };
      const res = await queryBanners(parm);
      if (res.success == true) {
        this.banners = res.response.data;
      }
      let parm1 = {
        pageIndex: 1,
        pageSize: 4,
      };
      const res1 = await queryNewestArtist(parm1);
      if (res1.success == true) {
        this.signContractList = res1.response.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.artExhibition {
  padding: 0 15px;
  min-height: 100vh;
  background-color: #fff;
  .artExhibition_search {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 6px 0;
    .van-icon {
      margin-left: 15px;
    }
    /deep/.van-search {
      padding: 0;
      width: 100%;
      .van-search__content {
        background-color: #eeeeed;
      }
      .van-field__control::placeholder {
        color: #999;
      }
    }
  }
  .tabs {
    padding-bottom: 10px;
    .van-col {
      text-align: center;
    }
    img {
      width: 72px;
      height: 72px;
    }
    p {
      line-height: 1;
      color: #333;
      margin-bottom: 10px;
    }
  }
  .my-swipe {
    margin: 10px 0;
    border-radius: 10px;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // height: 160px;
      text-align: center;
      background-color: #000;
      img,
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
  .title {
    font-size: 15px;
    font-weight: 800;
    padding: 15px 0;
  }
  .contract {
    width: 100%;
    height: 140px;
    overflow: hidden;
    ul {
      display: -webkit-box;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      li + li {
        margin-left: 50px;
      }
    }
    .c_img {
      img {
        width: 86px;
        height: 86px;
        border-radius: 2px;
      }
      img + img {
        margin-left: 5px;
      }
    }
  }

  .headimg {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 30px;
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      // margin-right: 10px;
    }
  }
  .release {
    .works_img {
      width: 100%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        // max-height: 345px;
        min-height: 145px;
      }
    }
    .works_introduce {
      position: absolute;
      // width: 100%;
      left: 15px;
      bottom: 20px;
      p {
        color: #ffffff;
        &:nth-child(1) {
          font-size: 18px;
          font-weight: 800;
        }
        &:nth-child(2) {
          margin-top: 5px;
        }
      }
    }
    .headimg {
      margin-top: 10px;
    }
  }
}
</style>
